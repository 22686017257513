import React from "react"
import { graphql } from "gatsby"
import { Row, Container, Col } from "react-bootstrap"

import Nav from "../components/UI/Nav/Nav"
import { Link } from "gatsby"
import HeadImage from "../components/UI/HeadImage/HeadImage"
import Footer from "../components/UI/Footer/Footer"
import Back from "../assets/icons/arrow_left.svg"
import SEO from "../components/seo"

import "./news.css"

export const query = graphql`
  query PageQuery($id: String) {
    prismic {
      allNewss(id: $id) {
        edges {
          node {
            title
            article
            image
            imageSharp {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 270, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

const Page = props => {
  const doc = props.data.prismic.allNewss.edges.slice(0, 1).pop()
  if (!doc) return null
  const news_body = []
  doc.node.article.map((item, index) => {
    if (item.type === "paragraph" && item.spans.length === 0)
      news_body.push(<p key={"p" + index}>{item.text}</p>)
    else if (item.type === "image")
      news_body.push(
        <div className="d-flex justify-content-center align-items-center">
          <img
            alt={"image-news-body" + index}
            className="img-fluid"
            src={item.url}
          />
        </div>
      )
    else if (item.type === "paragraph" && item.spans.length > 0)
      news_body.push(
        <div class="link-news">
          <p>
            <a
              href={item.spans[0].data.url}
              target="_BLANK"
              rel="noopener noreferrer"
            >
              Descargar Elementos
            </a>
          </p>
        </div>
      )
    return null
  })

  return (
    <Container className="no-gutters" fluid>
      <SEO />
      <Nav />
      <Row>
        <HeadImage
          image={doc.node.imageSharp.childImageSharp.fluid}
          height={330}
          black
          overlay
          news
        >
          <h1>{doc.node.title[0].text}</h1>
        </HeadImage>
      </Row>
      <Row className="justify-content-center items-container">
        <Col xs="12" sm="10" md="7">
          <div className="back-news">
            <Link to="/#news">
              <Back /> <span>Ir a inicio</span>
            </Link>
          </div>
          {news_body}
        </Col>
      </Row>
      <Footer />
    </Container>
  )
}

export default Page
